/* ibm-plex-mono-700 - latin */
@font-face {
  font-family: 'PlexMono';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('./fonts/ibm-plex-mono-v11-latin-700.woff2') format('woff2'),
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./fonts/ibm-plex-mono-v11-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-mono-700italic - latin */
@font-face {
  font-family: 'PlexMono';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('./fonts/ibm-plex-mono-v11-latin-700italic.woff2') format('woff2'),
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./fonts/ibm-plex-mono-v11-latin-700italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: 'PlexSans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('./fonts/ibm-plex-sans-v13-latin-500.woff2') format('woff2'),
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./fonts/ibm-plex-sans-v13-latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: 'PlexSans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('./fonts/ibm-plex-sans-v13-latin-regular.woff2') format('woff2'),
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./fonts/ibm-plex-sans-v13-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Interstate */
@font-face {
  font-family: 'Interstate';
  font-style: 500;
  src: local(''),
  url('./fonts/interstate-light.woff2') format('woff2'),
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./fonts/interstate-light.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  padding: 0;
  font-family: 'PlexSans', sans-serif;
  background: black;
  color: white;
  margin: 0;
  height: 100vh;
  font-size: 12px;
}

code {
  font-family: 'PlexMono', Courier, monospace;
}

a {
  color: #5a8bff;
}

a:hover {
  opacity: 0.8;
}

button {
  font-family: 'PlexSans', sans-serif;
  font-weight: 500 ! important;
}

p {
  margin: 0 0 30px;
  line-height: 28px;
  font-size: 18px;
}

p:last-child {
  margin: 0;
}

h1 {
  margin: 0 0 44px;
  line-height: 58px;
  font-size: 54px;
  font-family: 'PlexSans', Courier, monospace;
}

h2 {
  margin: 0 0 40px;
  line-height: 38px;
  font-size: 28px;
  font-family: 'PlexMono', Courier, monospace;
}

h3 {
  margin: 0 0 40px;
  line-height: 38px;
  font-size: 28px;
  font-family: 'PlexMono', Courier, monospace;
}

.sc {
  font-style: normal;
  /* disable italics */
  font-variant: small-caps;
}

.label {
  margin: 0 0 30px;
  font-size: 18px;
  font-family: 'PlexSans', sans-serif;
}

@media only screen and (max-width: 640px) {
  h1 {
    margin: 0 0 44px;
    line-height: 44px;
    font-size: 40px;
  }

  h2 {
    margin: 0 0 30px;
    line-height: 38px;
    font-size: 28px;
    font-family: 'PlexMono', Courier, monospace;
  }

  h3 {
    margin: 0 0 30px;
    line-height: 38px;
    font-size: 28px;
    font-family: 'PlexMono', Courier, monospace;
  }

  .label {
    margin: 0 0 20px;
    font-size: 16px;
  }
}

.container {
  box-sizing: border-box;
  height: 100vh;
  /* padding: 32px; */
}

.prejoin {
  height: 100vh;
}

.prejoin main {
  width: 100%;
}

.prejoin h2 {
  text-align: center;
  margin-top: 30px;
}

.prejoin hr {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.prejoin footer {
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.entrySection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label .labelHint {
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "PlexSans", sans-serif;
  margin-left: 0.5rem;
}

.privacyLabels {
  margin-top: -100px;
  margin-right: 0px;
  margin-left: 1rem;
  padding-top: 30px;
  font-size: 1rem;
}

.showStatsContainer {
  font-family: "PlexSans", sans-serif;
}

.privacyLabels .metadataSecurity {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  font-weight: 500;
  font-size: 14px;
}

.privacyLabels svg  {
  margin-left: 0.1rem;
  margin-right: 0.4rem;
  width: 1rem;
  height: 1rem;
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 8px;
  }

  .entrySection {
    grid-template-columns: 1fr;
    margin-top: 20px;
    gap: 20px;
  }

  .prejoin main {
    width: 100%;
  }
}

.green {
  background-color: #004620aa;
}

button.green:hover {
  background-color: #004620;
}

.entrySection .label {
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}

.entrySection input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  padding: 8px;
}

.entrySection > div:nth-child(1) {
  width: 33%
}

.entrySection input[type="text"] {
  width: calc(100% - 16px);
}

.entrySection div {
  vertical-align: middle;
}

.videoSection {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-top: 20px;
  max-height: 58vh;
  overflow-y: hidden;
}

.videoSection video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.videoSection .placeholder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #2f2f2f;
}

.controlSection {
  margin-top: 30px;
}

.roomContainer {
  height: 95vh;
}

.topBar {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: flex-end;
}

.topBar .right {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  padding-right: 12px;
  padding-bottom: 12px;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: auto;
}

.participantCount {
  font-size: 14px;
}

.participantCount span {
  display: inline-block;
  margin-left: 5px;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, fit-content(120px)) auto;
  gap: 8px;
}

.options label {
  text-align: center;
  margin-left: 0.2rem;
  font-size: 0.9rem;
}

header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  z-index: 2;
}

.prejoin .boothlogo {
  color: rgba(55, 255, 255, 0);
  position: absolute;
  top: 25px;
  left: 8%;
  width: 260px;
  height: 125px;
  background-size: 250px;
  background-image: url("./img/boothfulllogo_reverse_concentric2.png");
  background-repeat: no-repeat;
}

@media only screen and (max-width: 640px) {
  .prejoin .boothlogo {
    top: 30px;
    background-size: 100px 29px;
    width: 100px;
    height: 29px;
  }
}

.roomContainer a.boothlogo {
  display: block;
}

.roomContainer .boothlogo {
  color: rgba(55, 255, 255, 0);
  height: 5vh;
  width: 10vh;
  background-size: 10vh 5vh;
  background-image: url("./img/boothfulllogo_reverse_concentric2.png");
  background-repeat: no-repeat;
}

.navlogo {
  color: rgba(255, 255, 255, 0);
  position: absolute;
  right: 8%;
  top: 10px;
  width: 140px;
  height: 28px;
  background-image: url("./img/logo-white.svg");
}

@media only screen and (max-width: 640px) {
  .navlogo {
    width: 110px;
    height: 22px;
  }
}

/* Main Nav */
nav {
  position: absolute;
  top: 65px;
  right: 8%;
}

nav ul {
  display: flex;
  gap: 48px;
  list-style: none;
  margin: 0;
  text-transform: uppercase;
  font-family: "PlexMono";
  font-size: 18px;
}

nav ul li {
  position: relative;
  margin: 0;
}

nav a {
  color: white;
  text-decoration: none;
}

nav li.is-selected::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

nav.is-dark a {
  color: black;
}

nav.is-dark li.is-selected::after {
  background-color: black;
}

@media only screen and (max-width: 640px) {
  nav {
    top: 28px;
  }

  nav ul {
    font-size: 15px;
    gap: 21px;
  }

  nav.sm-light a {
    color: white;
  }

  nav.sm-light li.is-selected::after {
    background-color: white;
  }
}

/* Hero */
.hero {
  margin: auto;
  position: relative;
  color: white;
  text-align: center;
}

.hero .content {
  margin: 20px auto 0px auto;
  width: 66%;
}

.hero.is-short {
  min-height: auto;
  height: auto;
  text-align: left;
}

.hero.is-short .content {
  padding: 150px 50px 50px 50px;
  margin: 0 auto;
  width: 800px;
}

@media only screen and (max-width: 640px) {
  .hero {
    height: auto;
    min-height: none;
  }

  .hero .content {
    max-width: 800px;
    padding: 32vw 8vw 8vw 8vw;
  }

  .hero.is-short .content {
    padding: 28vw 8vw 8vw 8vw;
    margin: 0 auto;
    max-width: 800px;
  }
}

.browserSupportContainer {
  text-align: center;
  margin-top: 40px;
}

.tooltip {
  position: absolute;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 30vw;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 25px;
  left: 15vw;
  opacity: 0;
  transition: opacity 0.3s;
  transform:translateX(-50%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

